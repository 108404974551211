.authorization {
  &-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(127, 127, 127, 0.90);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
  }

  &-modal {
    color: #000;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    border-radius: 15px;
    background: #FFF;
    max-width: 480px;
    max-height: calc(100vh);
    padding: 15px 63px 55px;
    position: relative;
    object-fit: fill;
    overflow: auto;

    &-wider {
        width: 562px;
    }

    &-fit-content {
        max-width: fit-content;
    }

    &-content {
      z-index: 1001;
      position: relative;
    }

    &-with-background {
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background-image: url("../images/background-modal.png");
        background-repeat: no-repeat, no-repeat;
        background-position: -484px -366px;
      }

      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1000;
        right: 0;
        bottom: 0;
        background-image: url("../images/background-modal.png");
        background-repeat: no-repeat, no-repeat;
        background-position: -612px -286px;
        transform: rotate(180deg);
      }
    }

    &-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;

      img {
        width: 84px;
        height: 34px;
        filter: opacity(0.5);
      }
    }

    &-header {
      line-height: 150%; /* 30px */

      font-size: 20px;
      text-align: center;
      margin-bottom: 15px;

      &--marked {
        color: #2E5855;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 166.5%; /* 33.3px */
        padding-bottom: 10px;
      }
    }

    &-body {
      color: #848484;
      font-size: 16px;
      line-height: 150%;
      font-family: 'Poppins';

      &--password-holder {
        margin: 35px;
      }

      strong {
        font-family: "Open Sans";
      }
      dd {
        margin-left: 15px !important;
      }

      .stack {
        display: flex;
        flex-direction: column;
        gap: 30px;
        &-centered {
          margin: 30px auto;
          text-align: center;
        }
      }

    }

    &-footer {
      margin-top: 45px;
      display: flex;
      justify-content: center;
      gap: 7px;
    }
  }
}