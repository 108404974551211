.bidder {
  &-selector {
    position: relative;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    &__main {
      width: 289px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: linear-gradient(90deg, #C3DFDC 0%, #F3F9F8 100%);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
      border-radius: 32px;
      color: #46837F;

      &-icon {
        margin-left: 20px;
        svg path {
          fill: #46837F;
        }
      }
    }

    &__overlay {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(5px);
      z-index: 9999;
    }

    &__list {
      position: absolute;
      min-width: 289px;
      left: 0;
      top: 66px;
      z-index: 99999;
      background: #F3F9F8;

      box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 32px;
      overflow: hidden;

      &__item {
        border-bottom: 2px solid #fff;
        padding: 16px 56px;
        text-align: left;
        line-height: 24px;
        color: #46837F;
        width: max-content;
        min-width: 100%;

        &.selected {
          color: #464646;
          opacity: 0.5;
          pointer-events: none;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}