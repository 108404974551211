.activity-log {
  &-content {
    max-height: 400px;
    overflow-y: auto;
    margin: 15px 0 30px;
    .activity-log-item {
      color: #464646;
      font-family: 'Poppins';
      margin: 5px 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}