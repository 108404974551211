.deadline-grid-container {
  display: flex;

  .bidder-panel {
    flex-grow: 0;
    flex-shrink: 0;
    flex: 0 0 330px;
  }

  .bidder-panel:first-child {
    margin-right: 30px;
    flex: auto;
  }
}

