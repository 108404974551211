.biddings-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #464646;

  &-header th {
    font-weight: 600;
    text-align: center;
  }

  &-header tr:last-child {
    th {
      border-bottom: 0.5px solid #46837F;
    }
  }

  &-body td {
    font-weight: 400;
    border-bottom: 0.5px solid #46837F;
    padding: 16px;
    text-align: center;

    &:first-child {
      //padding-left: 0;
    }

    &:last-child {
      //padding-right: 0;
    }
  }

  &-body tr {
    &.pointer {
      cursor: pointer;
    }

    &:hover {
      background-color: #F5F5F5;
    }
  }

  &-body tr:last-child {
    td {
      border-bottom: none;
    }
  }

  &__row-macro {
    td {
      background: #efefef;
    }

    &:hover {
      td {
        background: #f5f5f5;
      }
    }
  }

  .align-left-column {
    text-align: left !important;
  }
}