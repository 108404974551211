.vendor-valoration-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  color: #464646;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &__header, &__body {
    th, tr {
      td, th:first-child {
        text-align: left;
      }
    }
  }

  &__header {
    th {
      font-weight: 600;
      padding: 16px;
      text-align: center;
    }

    tr:last-child {
      th {
        border-bottom: 0.5px solid #46837F;
      }
    }
  }

  &__body {
    tr {
      &.pointer {
        cursor: pointer;
      }

      &:hover {
        background-color: #F5F5F5;
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    td {
      font-weight: 400;
      border-bottom: 0.5px solid #46837F;
      padding: 16px;
      text-align: left;
    }
  }
}
