.upcoming-deadlines-table-wrapper-wrapper {
  background-color: #F3F9F8;
  margin: 15px;
  border-radius: 32px;
  padding: 16px 20px 16px 20px;
  width: 100%;

}

.upcoming-deadlines-table-wrapper {
  max-height: 259px; /* Фиксированная высота контейнера */
  overflow: auto; /* Включение вертикальной прокрутки */
  height: 100%;
}

.upcoming-deadlines-table {

  width: 100%;
  thead th {
    background: #F3F9F8;
    position: sticky; /* Заголовок прилипает к верху */
    top: 0;
    padding: 10px 16px 10px;
    border-bottom: 2px solid #fff;
    z-index: 1; /* Установка z-index для отображения над телом таблицы */
  }
  tbody tr td {
    padding: 12px;
    border-bottom: 0;
  }

  tbody tr.date-is-due td {
    background: #eeeeee;
  }

}