.bidder {
  &-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &-row {
      &--selected {
        background-color: #F5F5F5;
      }

      &--pointer {
        cursor: pointer;
      }
    }
    &.table-fixed {
        table-layout: fixed;
    }

    &-header th {
      font-weight: 600;
      color: #464646;

      padding: 16px;
      text-align: center;
      .header-wrapper {
        justify-content: space-between;
        display: inline-flex;
        flex-wrap: wrap;
      }
      &:first-child {
        //padding-left: 0;
      }

      &:last-child {
        //padding-right: 0;
      }
    }

    &-header tr:last-child {
      th {
        border-bottom: 0.5px solid #46837F;
      }
    }

    &-body td {
      font-weight: 400;
      color: #464646;
      border-bottom: 0.5px solid #46837F;
      padding: 16px;
      text-align: center;

      &:first-child {
        //padding-left: 0;
      }

      &:last-child {
        //padding-right: 0;
      }
    }

    &-body tr {
      &.pointer {
        cursor: pointer;
      }

      &:hover {
        background-color: #F5F5F5;
      }
    }

    &-body tr:last-child {
      td {
        border-bottom: none;
      }
    }

    .align-left-column {
      text-align: left !important;
    }

    .success-row {
        td {
          background-color: #E5F9F8;
        }
    }

    &-review {
      .bidder-table-header th {
          padding: 5px;
      }
      .bidder-table-body td {
        padding: 5px;
      }
    }
  }
}


@import "tables/vendor-valoration-table";
@import "tables/biddings-table";