.icon {
  &-20 {
    width: 20px;
    height: 20px;
  }
  &-pointer {
    cursor: pointer;
  }

  &-inactive {
    opacity: 0.5;
  }
}