.cursor-pointer {
    cursor: pointer;
}

.overflow-hidden {
    overflow: hidden;
}

.force-text-center {
    text-align: center !important;
}

.force-text-left {
    text-align: left !important;
}

.force-text-right {
    text-align: right !important;
}
