.bidder-big-button__button {
  font-family: 'Poppins';
  color: #67B1C5;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;

  width: 130px;
  height: 50px;

  border-radius: 32px;
  border: none;
  margin: 0 15px 15px;
  /* White */

  background: #ffffff;
  /* Main Shadow */

  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  &:focus {

  }
  &[aria-selected='true'] {
    background: #f3f9f8;
    /* Inner Shadow */

    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.08);
  }
  :disabled {
    /* White */
    pointer-events: none;
    background: #ccc;
    color: #fff;
    /* Main Shadow */

    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
  }

  &.active {
    background: #f3f9f8;
    /* Inner Shadow */

    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.08);
    color: #64AD90
  }
}