.bidder-chart-block-wrapper {

}

.bidder-chart-block--header {

}

.bidder-chart-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  padding: 15px;
}

.bidder-chart-block__chart {
  flex: auto;
  max-height: 300px;
}

.bidder-chart-block__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 33px;
}

.bidder-chart-block__buttons__button {
  width: 150px;
  height: 45px;
  margin-bottom: 33px;
  background: #82CAB0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 45px;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}