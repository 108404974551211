.search-input {
    position: relative;
    width: 100%;
    max-width: 520px;
}

.search-input input {
    height: 38px;
    background: #FFFFFF;
    border: 2px solid #345E64;
    border-radius: 12px;
    padding-left: 55px;
    padding-right: 16px;
    width: 100%;
}

.search-icon {
    position: absolute;
    left: 20px;
    top: 7px
}