.bidder {
  &-panel {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    padding: 24px;

    &__title {
      h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #464646;
        margin: 0;
      }
    }

    &__content {
    }
  }
}