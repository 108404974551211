.notifications-wrapper {
  display: flex;
  flex-direction: row;;
  justify-content: space-between;
  margin: 15px 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;

  .notifications__list {
    background: #FFFFFF;
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    margin-right: 24px;
    height: 280px;
    flex: 0 0 282px;
    overflow: auto;

    &-item {
      &:first-child {
        box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.08);
      }

      border-bottom: 1px solid #fff;
      min-height: 40px;
      display: flex;
      padding: 16px;
      flex-direction: row;
      background: #F3F9F8;

      &.selected {
        background: #fff;
      }

      &.no-exist {
        background: #fff;
        border-bottom: 1px solid #fff;
      }

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }

      &-content {
        flex: 1 0 auto;
      }

      &-status {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
      }

      &-content {
        &__title {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #464646;

        }

        &__short-content {
          font-weight: 400;
          font-size: 8px;
          line-height: 12px;
          color: #464646;
        }
      }
    }
  }

  .notifications__content {
    background: #FFFFFF;
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    height: 280px;
    overflow: auto;
    flex-grow: 1;

    &-body {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #464646;
      padding: 0 24px 16px;
    }

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      border-bottom: 1px solid #fff;

      &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #464646;
      }

      &__date {
        font-weight: 400;
        font-size: 8px;
        line-height: 12px;
        /* identical to box height */


        /* Colors-Text/TextoPrincipal */

        color: #40464C;
      }
    }
  }
}

.notifications__list-item-read {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;

  &.readed {
    background: #46837F;
  }

  &.unreaded {
    background: #464646;
  }
}