.questions-table-wrapper {
  max-height: 400px;
  margin-bottom: 15px;
  overflow: auto;
}

.questions-table {
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;

  thead th {
    background: #fff;
    position: sticky;
    top: 0;
    padding: 10px 16px 10px;
    z-index: 1;
  }

  tbody {
    tr {
      &.selected {
        td {
          background: #F5F5F5;
        }
      }
      td {
        padding: 12px;
        border-bottom: 0;
      }
    }

    tr.selected td {
      .question-index {
        color: #464646;
      }
    }
  }


  .question-index {
    color: rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }

  .question-vendor-name {
    vertical-align: middle;
    color: #464646;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    width: fit-content;
  }

  .question-content {
    width: 40%;
  }
}


.question-text-block {
  border-radius: 32px;
  padding: 11px 26px;
  max-width: fit-content;

  &.question {
    background: #D7F5EB;
    border-radius: 32px 32px 32px 0;
  }

  &.answer {
    margin-left: auto;
    background: #D3F3F9;
    border-radius: 32px 32px 0 32px;
  }

  .question-text {
    font-size: 16px;
    line-height: 24px;
  }

  .question-date {
    text-align: right;
    font-size: 12px;
    line-height: 18px;
    color: #464646;
  }
}

.questions-actions-wrapper {

}