.bidder-wrapper {
  .content {
    max-width: 1230px;
    margin: auto;
    padding: 55px 10px 115px;
    background-color: #fff;
  }

  .panel {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    padding: 24px;

    &__title {
      h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
        color: #464646;
        margin: 0;
      }

      margin-bottom: 24px;
    }

    &__content {
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffff;
    height: 74px;
    line-height: 100%;
    max-width: 1250px;
    margin: auto;

    &__menu {
      padding: 10px;
      flex-grow: 0;

      svg {
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }

    }

    &__widgets {
      display: flex;
      gap: 10px;
    }

    &__content {
      max-width: 1250px;
      margin: auto;
      padding-left: 10px;
      padding-right: 10px;
      background: #fff;
      position: sticky;
      top: 0;
      z-index: 100;
    }

    &__title {
      h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;

        color: #000000;
      }

      text-align: center;
      flex-grow: 1;
    }

    &__logo {
      flex-grow: 0;
    }
  }

  .inner-shadow-panel {
    background: #FFFFFF;
    /* Inner Shadow */

    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    border-radius: 32px;
    overflow: hidden;
  }
}


.bidder-linear-progress {
  background: #FFFFFF;
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  height: 40px;

  &__progress {
    background: #FFC107;
    border-radius: 32px;
    height: 100%;
    line-height: 40px;
    padding: 0 8px;
    box-sizing: border-box;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    display: inline-block;
    white-space: nowrap;
    min-width: min-content;
  }
}


@import "icons";
@import "buttons";
@import "notifications";
@import "questions";
@import "upcomming-deadlines";
@import "grid";
@import "common";
@import "swiper";
@import "chart-block";
@import "selector";
@import "panel";
@import "table";
@import "activity-log";
@import "authorization";

@import "dashboard";

@import "vendor";

@import "widgets";