.notification-widget {
  position: relative;
  font-family: 'Poppins', sans-serif;

  &__button {
    display: flex;
    height: 40px;
    width: 40px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    color: #000;
    box-sizing: border-box;
    border: 1px solid var(--gray-200, #EAECF0);
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &--disabled {
        background-color: #EAECF0;
        color: #BEC3C9;
        border: 0;
        cursor: not-allowed;
    }
    svg path {
      stroke: #344054;
    }

    &--purple {
      background-color: #9E77ED;
      color: #fff;
      svg path {
        stroke: #fff;
      }
      border: 0;
    }

    &--orange {
      background-color: #F4BF4E;
      color: #fff;
      svg path {
        stroke: #fff;
      }
      border: 0;
    }
  }

  &__content {
    position: absolute;
    top: calc(100% + 7px);
    left: 0;
    width: 490px;
    z-index: 999999;
    border-radius: 10px;
    background: #FFF;
    padding: 10px 24px 30px;
    box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.20), 0px 9px 46px 0px rgba(0, 0, 0, 0.12), 0px 24px 38px 0px rgba(0, 0, 0, 0.14);

    &__header {
      margin: 20px auto;
      span {
        color: #5F5F5F;
        line-height: 100%;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
      }
    }

    &__body {
      border-radius: 32px;
      padding: 0 10px;
      background: #F3F9F8;

      &__item {
        &:not(:last-child) {
          border-bottom: 2px solid #FFF;
        }
        padding: 15px 20px 20px;
        &__header {
          color: #3E7A65;
          text-align: left;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 125% */
          margin-bottom: 15px;
        }

        &__body {
          color: #000;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 100% */
        }
      }
    }
  }
}